import {axiosPrivate} from "../api/axiosPrivate";
import {STUDIO_CONTACT_UPDATE_FIELDS, STUDIO_STATUS_BACKEND_MAP, STUDIO_UPDATE_FIELDS} from "./studioModel";
import {pick} from "lodash";
import {COMM_TYPE_SMS, COMM_TYPE_WA} from "../communications/communicationModel";

export const getCrmStudios = async ({queryKey}) => {
    const [, , studioStatusKey, contactName, studioName] = queryKey
    const studioStatus = studioStatusKey && STUDIO_STATUS_BACKEND_MAP[studioStatusKey.toString()]
    const params = {}
    if (studioStatus) {
        params[studioStatus] = true
    }
    if (contactName) {
        params.contactName = contactName
    }
    if (studioName) {
        params.studioName = studioName
    }
    const response = await axiosPrivate.get('/api/v1/crmstudios', {params})
    return response.data
}

export const getCrmStudioNames = async () => {
    const response = await axiosPrivate.get('/api/v1/crmstudios/names')
    return response.data
}

export const getCrmStudioGivenUserId = async (userId) => {
    const response = await axiosPrivate.get('/api/v1/crmstudios', {params: {userId}})
    return response.data?.length ? response.data[0] : undefined
}

export const getSingleCrmStudio = async ({queryKey}) => {
    const [, id, params] = queryKey
    const response = await axiosPrivate.get(`/api/v1/crmstudios/${id}`, {params})
    return response.data
}

export const getSingleCrmStudioContacts = async ({queryKey}) => {
    const [, , id] = queryKey
    if (!id) {
        return undefined
    }
    const response = await axiosPrivate.get(`/api/v1/crmstudios/${id}/contacts`)
    return response.data
}

export const getSingleCrmStudioCampaigns = async ({queryKey}) => {
    const [, , id] = queryKey
    const response = await axiosPrivate.get(`/api/v1/crmstudios/${id}/campaigns`)
    return response.data
}

export const insertCrmStudioContact = async (studioContact) => {
    if (!studioContact.studio_id) {
        throw new Error('missing studio id')
    }
    const response = await axiosPrivate.post(`/api/v1/crmstudios/${studioContact.studio_id}/contacts`, studioContact)
    return response.data
}

export const deleteCrmStudioContact = async (studioId, studioContactId) => {
    const response = await axiosPrivate.delete(`/api/v1/crmstudios/${studioId}/contacts/${studioContactId}`)
    return response.data
}

export const updateCrmStudioContact = async (studioContact) => {
    const dbStudioContact = pick(studioContact, STUDIO_CONTACT_UPDATE_FIELDS)
    const response = await axiosPrivate.put(`/api/v1/crmstudios/${studioContact.studio_id}/contacts/${studioContact.id}`, dbStudioContact)
    return response.data
}

export const importUserChanges = async () => {
    const response = await axiosPrivate.post('/api/v1/crmstudios/userchanges')
    return response.data
}
export const updateCrmStudio = async (studio) => {
    const dbStudio = pick(studio, STUDIO_UPDATE_FIELDS)
    const response = await axiosPrivate.put(`/api/v1/crmstudios/${studio.id}`, dbStudio)
    return response.data
}
export const insertCrmStudio = async (studio) => {
    const dbStudio = pick(studio, STUDIO_UPDATE_FIELDS)
    const response = await axiosPrivate.post(`/api/v1/crmstudios`, dbStudio)
    return response.data
}

export const deleteCrmStudio = async (studioId) => {
    const response = await axiosPrivate.delete(`/api/v1/crmstudios/${studioId}`)
    return response.data
}

export const downloadCrmStudioContacts = async () => {
    const response = await axiosPrivate.get('/api/v1/crmstudios/contactdetails/csv', {responseType: 'blob'})
    return response.data
}

export const getCrmStudioDuplicates = async () => {
    const response = await axiosPrivate.get('/api/v1/crmstudios/duplicates')
    return response.data
}

export const getCrmStudioDuplicateChecked = async () => {
    const response = await axiosPrivate.get('/api/v1/crmstudios/duplicates/checked')
    return response.data
}

export const deleteCrmStudioDuplicateChecked = async (row) => {
    const {id_from, id_to} = row
    const response = await axiosPrivate.delete(`/api/v1/crmstudios/duplicates/checked/${id_from}/${id_to}`)
    return response.data
}
export const mergeCrmStudioDuplicates = async (duplicates) => {
    const {id, duplicateId} = duplicates
    const response = await axiosPrivate.post('/api/v1/crmstudios/duplicates/merge', {id, duplicateId})
    return response.data
}

export const addCrmStudioDuplicateExclusions = async (duplicates) => {
    const {id, doublet_id} = duplicates
    const response = await axiosPrivate.post('/api/v1/crmstudios/duplicates/checked', {idTo: id, idFrom: doublet_id})
    return response.data
}

export const broadcastStudios = async (messageConfig) => {
    const response = await axiosPrivate.post('/api/v1/crmstudios/broadcast', messageConfig)
    return response.data
}

export const postSingleMessageToStudio = async (studioId, messageConfig) => {
    const path = messageConfig.msgType === COMM_TYPE_SMS ? '/sms' : (messageConfig.msgType === COMM_TYPE_WA? '/whatsapp' : '/email')
    const url = `/api/v1/crmstudios/${studioId}` + path
    const response = await axiosPrivate.post(url, messageConfig)
    return response.data
}

