import React, {useEffect, useState} from 'react';
import StartToolbar from "./StartToolbar";
import {Box, styled} from "@mui/material";
import {Outlet} from "react-router-dom";
import InfoToaster from "./InfoToaster";
import useLastInboundCall from "../common-hooks/useLastInboundCall";
import {setLastInboundCallId, useStateContext} from "../context/StateProvider";
import InboundCallNotificationDialog from "./InboundCallNotificationDialog";

const Offset = styled('div')(({theme}) => theme.mixins.toolbar);
const App = () => {
    const [calls, setCalls] = useState()
    const {dispatch} = useStateContext()

    const lastInboundCalls = useLastInboundCall()
    useEffect(() => {
        if (lastInboundCalls?.length) {
            const lastCallId = lastInboundCalls.reduce((result, call) => call.id > result ? call.id : result, 0)
            setCalls(lastInboundCalls)
            dispatch(setLastInboundCallId(lastCallId))
        }
    }, [dispatch, lastInboundCalls])
    return (
        <>
            <Box display="flex" flexDirection="column" height="100vh" bgcolor="background.default" color="text.primary" width="100%">
                <StartToolbar />
                <Offset/>
                <Box sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    minHeight: 0,
                    width: "100%"
                }}>
                    <Outlet />
                    <InfoToaster />
                </Box>
            </Box>
            {calls ? <InboundCallNotificationDialog inboundCalls={calls} onClose={() => setCalls(null)} /> : null }
        </>
    );
};

export default App;
