import React, {useCallback} from 'react';
import {Box, Dialog, DialogContent, DialogTitle} from "@mui/material";
import InboundCallList from "./InboundCallList";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const InboundCallNotificationDialog = ({inboundCalls, onClose}) => {
    const open = Boolean(inboundCalls)
    const navigate = useNavigate()
    const {t} = useTranslation()
    const handleNavigate = useCallback(call => {
        onClose()
        navigate(`/studios/${call.studio_id}`)
    }, [navigate, onClose])
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>{t('INBOUND_CALL_DIALOG_HEADER')}</DialogTitle>
            <DialogContent>
                <Box height="300px">
                    <InboundCallList inboundCalls={inboundCalls}
                                     onRowDoubleClick={params => handleNavigate(params.row)}/>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default InboundCallNotificationDialog;
