import {dateValueGetter} from "../utils/dateHelper";

export const getInboundCallColumns = (t) => [
    {field: "id", type: "number", headerName: "Call ID"},
    { field: "studio_id", type: "number", headerName: "Studio ID" },
    { field: "studio_contact_id", type: "number", headerName: "Studio Contact ID" },
    { field: "_createdTS", type: 'dateTime', headerName: t('CREATED_AT_LABEL'), minWidth: 150, valueGetter: dateValueGetter },
    { field: "phone", headerName: t('PHONE_LABEL'), minWidth: 150 },
    { field: "studio_name", headerName: t('STUDIO_NAME_LABEL'), minWidth: 150 },
    { field: "first_name", headerName: t('FIRST_NAME_LABEL') },
    { field: "last_name", headerName: t('LAST_NAME_LABEL') },
]
