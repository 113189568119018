import React from "react";
import {createRoot} from 'react-dom/client';
import './i18n';
import './yupLocalization';
import AppContainer from "./components/AppContainer";
import {StateContextProvider} from "./context/StateProvider";

const container = document.querySelector('#root')
const root = createRoot(container)

root.render(
    <React.StrictMode>
        <StateContextProvider>
            <AppContainer/>
        </StateContextProvider>
    </React.StrictMode>
);
