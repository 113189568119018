import React from 'react';
import {Alert, Box, Container, Paper, Typography} from "@mui/material";
import errorImage from '../public/images/error.png'
import {useTranslation} from "react-i18next";
import {useRouteError} from "react-router-dom";

const ErrorPage = () => {
    const {t} = useTranslation()
    const error = useRouteError();
    return (
        <Container maxWidth="md">
            <Paper>
                <img width="100%" src={errorImage} alt="error-image"/>
                <Alert severity="error">
                    {t('ERROR.INFO')}
                </Alert>
                <Box padding={2}>
                    <Typography variant="subtitle1"><i>{error.statusText || error.message}</i></Typography>
                </Box>
            </Paper>
        </Container>
    );
};

export default ErrorPage;
