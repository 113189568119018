import {useMutation, useQuery, useQueryClient} from "react-query";
import {setAuth, setAuthError, useStateContext} from "../../context/StateProvider";
import {useLocation, useNavigate} from "react-router-dom";
import {axiosPublic} from "../../api/axiosPublic";
import {useMemo} from "react";
import {parseJwt} from "../../utils/jwt-decode";
import {warehouseKeys} from "../../warehouse/warehouseQueries";
import {userKeys} from "../../users/userQueries";
import {studioKeys} from "../../studios/studioQueries";

export const authKeys = {
    all: ['auth']
}

const fetchAuth = async () => {
    const token = localStorage.getItem('token')
    if (token) {
        return {
            token,
            member: parseJwt(token)
        }
    }
}

export const useAuth = () => {
    const stateContext = useStateContext()
    const navigate = useNavigate()
    return useQuery({
        queryKey: authKeys.all,
        queryFn: fetchAuth,
        staleTime: 0,
        retry: 1,
        onSuccess: (data) => {
            if (!data) {
                navigate('/login')
            }
            stateContext.dispatch(setAuth(data))
        },
    })
}

const loginUserFn = async (loginData) => {
    const response = await axiosPublic.post('/api/v1/members/login', loginData)
    return response.data
}

const logoutUserFn = async () => {
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
}

export const useLogin = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const from = useMemo(() => location.state?.from.pathname || '/', [location])
    const queryClient = useQueryClient()
    const stateContext = useStateContext()
    const { mutate: loginUser, isLoading } = useMutation(
        (loginData) => loginUserFn(loginData),
        {
            onError: (err) => stateContext.dispatch(setAuthError(err.response?.status)),
            onSuccess: (data) => {
                const {token, refreshToken} = data
                localStorage.setItem('token', token)
                localStorage.setItem('refreshToken', refreshToken)
                queryClient.refetchQueries(authKeys.all).then(() => navigate(from));
            },
        }
    )
    return {loginUser, isLoading}
}

export const useLogout = () => {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const {mutate: logout} = useMutation(
        () => logoutUserFn()
        , {
            onSuccess: () => {
                navigate('/login')
                queryClient.refetchQueries(authKeys.all).then(() => {
                    queryClient.removeQueries(warehouseKeys.all)
                    queryClient.removeQueries(userKeys.all)
                    queryClient.removeQueries(studioKeys.all)
                })
            }
        }
    )
    return {logout}
}

export const removeTokens = () => {
    window.location.href = '/login'
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
}
