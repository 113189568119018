export const formatDate = (date, locale, timezone) => {
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timeZone: timezone
    }
    return new Intl.DateTimeFormat(locale, options).format(date)
}

export const formatDateTime = (date, locale, timezone) => {
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: timezone
    }
    return new Intl.DateTimeFormat(locale, options).format(date)
}

export const formatTime = (date, locale, timezone) => {
    const options = {
        hour: 'numeric',
        minute: 'numeric',
        timeZone: timezone
    }
    return new Intl.DateTimeFormat(locale, options).format(date)
}

export const dateValueGetter = ({value}) => value && new Date(value)


export const isNotOlderThan24Hrs = (date) => new Date().getTime() - new Date(date).getTime() < 24 * 3_600_000
