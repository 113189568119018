import React from 'react';

const Unauthorized = () => {
    return (
        <div>
            not authorized
        </div>
    );
};

export default Unauthorized;
