import {axiosPrivate} from "../api/axiosPrivate";

export const getLastInboundCall = async (lastId) => {
    const params = {lastId: lastId || 0}
    const response = await axiosPrivate.get('/api/v1/inbound-calls', {params})
    return response.data
}

export const getInboundCalls = async () => {
    const response = await axiosPrivate.get('/api/v1/inbound-calls')
    return response.data
}
