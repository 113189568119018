import {deDE, enUS} from "@mui/x-data-grid-pro";
import {selectLocale, useStateContext} from "../context/StateProvider";
import {useMemo} from "react";

export const dataGridTranslationMap = {
    de: deDE,
    en: enUS
}
export const useDataGrid = () => {
    const {state} = useStateContext()
    const locale = useMemo(() => selectLocale(state), [state])
    const translations = dataGridTranslationMap[locale].components.MuiDataGrid.defaultProps.localeText
    return {translations}
}
