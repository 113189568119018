export const SCROLLBAR_STYLES = {
    "&::-webkit-scrollbar": {
        width: 5
    },
    "&::-webkit-scrollbar-track": (theme) => ({
        background: theme.palette.action.selected
    }),
    "&::-webkit-scrollbar-thumb": (theme) => ({
        background: theme.palette.text.disabled
    }),
    "&::-webkit-scrollbar-thumb:hover": (theme) => ({
        background: theme.palette.text.primary
    })
}

export const DATAGRID_SCROLLBAR_STYLES = {
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
        width: 5,
        height: 5
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': (theme) => ({
        background: theme.palette.action.selected
    }),
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': (theme) => ({
        background: theme.palette.text.disabled
    }),
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': (theme) => ({
        background: theme.palette.text.primary
    })
}
