import axios from "axios";

import {BACKEND} from "../config/backend";
import {memoizedRefreshToken} from "./refreshtoken";
import {removeTokens} from "../features/auth/queries";

axios.defaults.baseURL = BACKEND;

axios.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem("token")
        if (token) {
            config.headers = {
                ...config.headers,
                authorization: `Bearer ${token}`,
            }
        }

        return config;
    },
    (error) => Promise.reject(error)
);

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        const config = error?.config;

        if (error?.response?.status === 401 && !config?.sent) {
            config.sent = true;

            const {token} = await memoizedRefreshToken();

            if (token) {
                config.headers = {
                    ...config.headers,
                    authorization: `Bearer ${token}`,
                };
            }

            return axios(config);
        } else if (error?.response?.status === 401) {
            removeTokens()
        }
        return Promise.reject(error);
    }
);

export const axiosPrivate = axios;
