import mem from "mem";

import {axiosPublic} from "./axiosPublic";
import {removeTokens} from "../features/auth/queries";

const refreshTokenFn = async () => {
    try {
        const rtoken = localStorage.getItem("refreshToken")
        const response = await axiosPublic.post("api/v1/members/refresh", {
            token: rtoken
        })

        const {token} = response.data;
        localStorage.setItem("token", token);

        return {token}
    } catch (error) {
        removeTokens()
        return {}
    }
};

const maxAge = 10000;

export const memoizedRefreshToken = mem(refreshTokenFn, {
    maxAge,
});
