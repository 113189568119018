import React, {useMemo} from 'react';
import {getInboundCallColumns} from "../inbound-calls/inboundCallModel";
import {useTranslation} from "react-i18next";
import {useDataGrid} from "../utils/useDataGrid";
import {DATAGRID_SCROLLBAR_STYLES} from "../styles/scrollbar";
import {DataGridPro} from "@mui/x-data-grid-pro";

const InboundCallList = ({inboundCalls, onRowDoubleClick}) => {
    const {t} = useTranslation()
    const colDefs = useMemo(() => getInboundCallColumns(t), [t])
    const {translations: dataGridTranslations} = useDataGrid()
    return (
        <DataGridPro
            localeText={dataGridTranslations}
            rows={inboundCalls}
            columns={colDefs}
            disableMultipleRowSelection
            onRowDoubleClick={onRowDoubleClick}
            sx={{
                ...DATAGRID_SCROLLBAR_STYLES,
                '& .MuiDataGrid-columnHeaderTitle': {
                    textOverflow: "clip",
                    whiteSpace: "break-spaces",
                    lineHeight: 1
                }
            }}
            initialState={{
                columns: {
                    columnVisibilityModel: {id: false, studio_id: false, studio_contact_id: false}
                }
            }}
            getRowClassName={(params) => params.row.campaign_studio_id ? 'selected' : ''}
        />
    );
};

export default InboundCallList;
