import React, {useCallback} from 'react';
import {Box, Button, Container, TextField} from "@mui/material";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useAuth, useLogin} from "./queries";
import {useTranslation} from "react-i18next";

const schema = yup.object().shape({
    kuerzel: yup.string().required(),
    password: yup.string().required(),
})
const Login = () => {
    const {isLoading, isFetching} = useAuth()
    const {t} = useTranslation()
    const {loginUser, isLoading: isProcessingLogin} = useLogin()
    const {register, handleSubmit, formState: {errors}} = useForm({
        mode: 'onTouched',
        defaultValues: {
            kuerzel: '',
            password: '',
        },
        resolver: yupResolver(schema)
    });

    const onSubmitHandler = useCallback((values, e) => {
        e.preventDefault()
        loginUser(values)
    }, [loginUser])

    return (
        <Container maxWidth="xs">
            <Box display="flex" flexDirection="column" mt={2} gap={1} component='form' autoComplete='off' noValidate
                 onSubmit={handleSubmit(onSubmitHandler)}>
                <Box flex="0 0 auto">
                    <TextField autoFocus
                               fullWidth
                               {...register('kuerzel')}
                               error={!!errors?.kuerzel}
                               required
                               variant="standard"
                               label={t('MEMBER_SHORT_NAME_LABEL')}/>
                </Box>
                <Box flex="0 0 auto">
                    <TextField
                        fullWidth
                        {...register('password')}
                        error={!!errors?.password}
                        required
                        type="password"
                        variant="standard"
                        label="Password"/>
                </Box>
                <Box flex="0 0 auto">
                    <Button
                        variant='contained'
                        sx={{mt: 1}}
                        fullWidth
                        type='submit'
                        disabled={isLoading || isFetching || isProcessingLogin}
                    >
                        Login
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default Login
