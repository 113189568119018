import React from 'react';
import {Navigate, Outlet, useLocation} from "react-router-dom";
import {useAuth} from "./queries";
import FullScreenLoader from "../../components/FullScreenLoader";
import {intersection} from "lodash/array";

const isAuthorized = (memberData, allowedRoles) => {
    if (!memberData?.member?.roles) {
        return false
    }
    const roles = memberData.member.roles.map(r => r.role)
    const intersectedRoles = intersection(roles, allowedRoles)
    return intersectedRoles.length > 0
}

const AuthGuard = ({allowedRoles}) => {
    const location = useLocation()
    const {isLoading, isFetching, data: memberData} = useAuth()
    const loading = isLoading || isFetching

    if (loading || isFetching) {
        return <FullScreenLoader />
    }

    return isAuthorized(memberData, allowedRoles) ? (
        <Outlet />
    ) : memberData ? (
        <Navigate to='/unauthorized' state={{ from: location }} replace />
    ) : (
        <Navigate to='/login' state={{ from: location }} replace />
    );
};

export default AuthGuard;
