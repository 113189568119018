import {dateValueGetter, formatDate} from "../utils/dateHelper";
import * as yup from "yup";
import { ALL_COUNTRIES } from "@kiss-solutions/countries/lib/countryModel";
import {COMM_TYPE_EMAIL, COMM_TYPE_SMS} from "../communications/communicationModel";
export const STUDIO_IS_ACTIVE_USER = 1;
export const STUDIO_HAS_USER_ID = 2;
export const STUDIO_IS_TEST_USER = 3;
export const STUDIO_IS_EXPIRED_TEST_USER = 4;
export const STUDIO_IS_RECENTLY_EXPIRED_TEST_USER = 5;
export const STUDIO_HAS_SEIT_NOT_SET = 6;
export const STUDIO_STATUS_MAP = {
    STUDIO_HAS_USER_ID,
    STUDIO_HAS_SEIT_NOT_SET,
    STUDIO_IS_ACTIVE_USER,
    STUDIO_IS_TEST_USER,
    STUDIO_IS_EXPIRED_TEST_USER,
    STUDIO_IS_RECENTLY_EXPIRED_TEST_USER,
};

export const STUDIO_STATUS_BACKEND_MAP = {
    [STUDIO_HAS_USER_ID]: "onlyUsers",
    [STUDIO_HAS_SEIT_NOT_SET]: "seitNotSet",
    [STUDIO_IS_ACTIVE_USER]: "onlyActiveUsers",
    [STUDIO_IS_TEST_USER]: "onlyTestUsers",
    [STUDIO_IS_EXPIRED_TEST_USER]: "onlyExpiredTestUsers",
    [STUDIO_IS_RECENTLY_EXPIRED_TEST_USER]: "onlyRecentlyExpiredTestUsers",
};

export const getStudioStatusSelection = (t) =>
    Object.keys(STUDIO_STATUS_MAP).map((key) => ({
        id: STUDIO_STATUS_MAP[key],
        text: t(key),
    }));


const STUDIO_SIZE_TYPE_SMALL = 1
const STUDIO_SIZE_TYPE_MEDIUM = 2
const STUDIO_SIZE_TYPE_LARGE = 3

export const STUDIO_SIZE_TYPE_MAP = {
    STUDIO_SIZE_TYPE_SMALL, STUDIO_SIZE_TYPE_MEDIUM, STUDIO_SIZE_TYPE_LARGE
}

const STUDIO_SIZE_TYPE_TRANSLATION_MAP = Object.keys(STUDIO_SIZE_TYPE_MAP).reduce((result, key) => {
    result[STUDIO_SIZE_TYPE_MAP[key]] = key
    return result
}, {})

export const getStudioSizeTypeSelection = (t) =>
    Object.keys(STUDIO_SIZE_TYPE_MAP).map((key) => ({
        id: STUDIO_SIZE_TYPE_MAP[key],
        text: t(key),
    }));


export const createStudioBaseSchema = () => {
    return yup.object().shape({
        studio_name: yup.string().min(5).max(100).required(),
        telefon: yup.string().max(30).nullable(),
        mobile: yup.string().max(30).nullable(),
        Herkunft: yup.string().max(15).nullable(),
        Vermittler: yup.string().max(100).nullable(),
        zip: yup.string().max(8).nullable(),
        city: yup.string().max(100).nullable(),
        street: yup.string().max(100).nullable(),
        house_no: yup.string().max(10).nullable(),
        studio_email: yup.string().email().max(150).nullable(),
        studio_instagram: yup.string().max(150).nullable(),
        studio_whatsapp: yup.string().max(150).nullable(),
        studio_facebook: yup.string().max(150).nullable(),
        member_rating: yup.number().required(),
        bounced: yup.boolean().required(),
        unsubscribed: yup.boolean().required(),
    });
};

export const CRM_STUDIO_INIT = {
    country_code: "DE",
    language: "de",
    studio_name: "neues Studio",
    member_rating: 0,
    bounced: false,
    unsubscribed: false,
    has_branch: false,
    studio_size_type: STUDIO_SIZE_TYPE_SMALL,
};

export const STUDIO_UPDATE_FIELDS = [
    "studio_name",
    "studio_website",
    "Herkunft",
    "Vermittler",
    "zip",
    "city",
    "country_code",
    "language",
    "telefon",
    "mobile",
    "isWhatsApp",
    "street",
    "house_no",
    "studio_email",
    "studio_instagram",
    "studio_whatsapp",
    "studio_facebook",
    "member_rating",
    "comment",
    "bounced",
    "unsubscribed",
    "has_branch",
    "studio_size_type",
];

export const STUDIO_CONTACT_UPDATE_FIELDS = [
    "studio_id",
    "first_name",
    "last_name",
    "type",
    "phone",
    "mobile",
    "isWhatsApp",
    "email",
    "language"
];

export const getStudioListColumns = (t) => [
    { field: "id", type: "number", headerName: "Studio ID" },
    { field: "studio_name", headerName: t('STUDIO_NAME_LABEL'), minWidth: 150 },
    { field: "studio_size_type", headerName: t('STUDIO_SIZE_LABEL'), valueGetter: ({value}) => value && t(STUDIO_SIZE_TYPE_TRANSLATION_MAP[+value]) },
    { field: "has_branch", type: 'boolean', headerName: t('STUDIO_BASE.REGISTRATION_HAS_BRANCHES_LABEL')},
    { field: "studio_email", headerName: "Email", minWidth: 200 },
    { field: "mobile", headerName: "Mobil", minWidth: 150 },
    { field: "isWhatsApp", type: 'boolean', headerName: "WhatsApp"},
    { field: "telefon", headerName: t('PHONE_LABEL') },
    { field: "studio_instagram", headerName: "Insta" },
    { field: "studio_whatsapp", headerName: "WhatsApp" },
    { field: "studio_facebook", headerName: "WhatsApp" },
    { field: "last_contact", type: "dateTime", headerName: t('STUDIO_BASE.LAST_CONTACT_LABEL'), valueGetter: dateValueGetter },
    { field: "country_code", headerName: t('STUDIO_BASE.REGISTRATION_COUNTRY_LABEL') },
    { field: "street", headerName: t('STUDIO_BASE.REGISTRATION_STREET_LABEL') },
    { field: "house_no", headerName: t('STUDIO_BASE.REGISTRATION_HOUSE_NUMBER_LABEL') },
    { field: "zip", headerName: t('STUDIO_BASE.REGISTRATION_ZIP_CODE_LABEL') },
    { field: "city", headerName: t('STUDIO_BASE.REGISTRATION_CITY_LABEL') },
    { field: "Herkunft", headerName: t('STUDIO_BASE.REGISTRATION_ORIGIN_LABEL') },
    { field: "UserID", type: "number", headerName: "UserID" },
    { field: "Vermittler", headerName: t('STUDIO_BASE.REGISTRATION_AFFILIATE_LABEL') },
    {
        field: "Seit",
        type: "date",
        headerName: t('STUDIO_BASE.USER_DATA_REGISTERED_AT_LABEL'),
        valueGetter: dateValueGetter,
    },
    {
        field: "TestBis",
        type: "date",
        headerName: t('STUDIO_BASE.USER_DATA_TRIAL_ENDED_LABEL'),
        valueGetter: dateValueGetter,
    },
    {
        field: "AboStart",
        type: "date",
        headerName: t('STUDIO_BASE.USER_DATA_SUBSCRIPTION_STARTED_AT_LABEL'),
        valueGetter: dateValueGetter,
    },
    {
        field: "AboEnde",
        type: "date",
        headerName: t('STUDIO_BASE.USER_DATA_SUBSCRIPTION_ENDED_AT_LABEL'),
        valueGetter: dateValueGetter,
    },
    {
        field: "abrechBis",
        type: "date",
        headerName: t('STUDIO_BASE.USER_DATA_BILLED_UNTIL_LABEL'),
        valueGetter: dateValueGetter,
    },
    {
        field: "gezBis",
        type: "date",
        headerName: t('STUDIO_BASE.USER_DATA_PAYED_UNTIL_LABEL'),
        valueGetter: dateValueGetter,
    },
    { field: "Kulanz", type: "number", headerName: t('STUDIO_BASE.USER_DATA_GOODWILL_DAYS_LABEL') },
    { field: "SignPadCount", type: "boolean", headerName: "KissSign" },
    { field: "AboMandatsRef", headerName: "Mandatsreferenz" },
    {
        field: "new_appointments",
        headerName: t("STUDIO.NEW_APPOINTMENTS_LABEL"),
    },
    { field: "new_customers", headerName: t("STUDIO.NEW_CUSTOMERS_LABEL") },
    { field: "new_email_messages", headerName: t("STUDIO.NEW_EMAILS_LABEL") },
    { field: "new_sms_messages", headerName: t("STUDIO.NEW_SMS_LABEL") },
];

export const COUNTRY_CODE_SELECTION = Object.keys(ALL_COUNTRIES).map((cc) => ({
    id: cc,
    text: ALL_COUNTRIES[cc].name,
}));
export const getStudioUserStatus = ({studio, t, locale}) =>
    studio.AboEnde ? t('FORMER_USER', {start: formatDate(new Date(studio.AboStart), locale), end: formatDate(new Date(studio.AboEnde), locale)})
        : (studio.AboStart ? t('ACTIVE_USER', {start: formatDate(new Date(studio.AboStart), locale)})
        : (studio.TestBis ? t('FORMER_TEST_USER', {start: formatDate(new Date(studio.Seit), locale), end: formatDate(new Date(studio.TestBis), locale)})
        : studio.Seit ? t('ACTIVE_TEST_USER', {start: formatDate(new Date(studio.Seit), locale), end: formatDate(new Date(studio.TestBis), locale)})
                : t('NO_USER')))

export const convertStudio2MessageReceiver = (studio) => {
    const result = []
    if (studio?.studio_email) {
        result.push({
            id: 'studio-email#' + studio.id,
            text: `studio email: ${studio.studio_email}`,
            receiverType: COMM_TYPE_EMAIL,
            receiver: studio.studio_email,
            isWhatsApp: false
        })
    }
    if (studio?.mobile) {
        result.push({
            id: 'studio-mobile#' + studio.id,
            text: `studio mobile: ${studio.mobile}`,
            receiverType: COMM_TYPE_SMS,
            receiver: studio.mobile,
            isWhatsApp: studio.isWhatsApp
        })
    }
    return result
}
