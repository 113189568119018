import React, {useCallback} from 'react';
import {Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import ImportExportIcon from '@mui/icons-material/ImportExport';
import SettingsIcon from '@mui/icons-material/Settings';
import {useDownloadStudioContacts} from "./useDownloadStudioContacts";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {useImportUserChanges} from "../home/useImportUserChanges";
import {useTranslation} from "react-i18next";
import SendIcon from '@mui/icons-material/Send';
import {useNavigate} from "react-router-dom";

const SettingsMenu = () => {
    const mutation = useImportUserChanges()
    const {t} = useTranslation()
    const navigate = useNavigate()
    const downloadStudioContacts = useDownloadStudioContacts()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [])

    const closeAndExecute = useCallback((handler) => {
        handleClose()
        handler()
    }, [handleClose])
    return (
        <Box flex="0 0 auto">
            <IconButton color="inherit"
                        onClick={handleClick}
                        id="settings-menue-button"
                        aria-controls={open ? 'settings-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
            >
                <SettingsIcon/>
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => closeAndExecute(mutation.mutate)}>
                    <ListItemIcon>
                        <ImportExportIcon/>
                    </ListItemIcon>
                    <ListItemText>{t('SETTINGS_IMPORT_CHANGES_MENU_TEXT')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => closeAndExecute(downloadStudioContacts)}>
                    <ListItemIcon>
                        <CloudDownloadIcon/>
                    </ListItemIcon>
                    <ListItemText>{t('SETTINGS_DOWNLOAD_CONTACTS_CSV_MENU_TEXT')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => closeAndExecute(() => navigate('/msg-templates'))}>
                    <ListItemIcon>
                        <SendIcon/>
                    </ListItemIcon>
                    <ListItemText>{t('SETTINGS_MSG_TEMPLATES_MENU_TEXT')}</ListItemText>
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default SettingsMenu;
