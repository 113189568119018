import {useMutation, useQueryClient} from "react-query";
import {importUserChanges} from "../../studios/studioApi";
import {studioKeys} from "../../studios/studioQueries";
import {addMessage, useStateContext} from "../../context/StateProvider";

export const useImportUserChanges = () => {
    const {dispatch} = useStateContext()
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: importUserChanges,
        onSuccess: (result) => {
            queryClient.invalidateQueries({queryKey: studioKeys.all}).then()
            dispatch(addMessage({type: 'info', message: `es wurden ${result.missing} studios hinzugefügt und ${result.differs} aktualisiert`}))
        }
    })
}
