import React, {lazy, Suspense, useEffect, useMemo} from 'react';
import {createTheme, CssBaseline, responsiveFontSizes, ThemeProvider, useMediaQuery} from "@mui/material";
import App from "./App";
import {selectLocale, selectThemeMode, setThemeMode, useStateContext} from "../context/StateProvider";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import ErrorPage from "./ErrorPage";
import Login from "../features/auth/Login";
import Unauthorized from "../features/auth/Unauthorized";
import AuthGuard from "../features/auth/AuthGuard";
import {QueryClient, QueryClientProvider} from "react-query";
import FullScreenLoader from "./FullScreenLoader";
import {ReactQueryDevtools} from "react-query/devtools";
import {amber, blue, deepPurple, green, indigo, lime, yellow} from "@mui/material/colors";
import {initializeMuiLicense} from "../config/muiLicense";
import {AdapterDateFns} from '@mui/x-date-pickers-pro/AdapterDateFns'
import {LocalizationProvider} from "@mui/x-date-pickers-pro";
import enLocale from "date-fns/locale/en-US";
import deLocale from "date-fns/locale/de";
import i18n from "i18next";

const localeMap = {
    en: enLocale,
    de: deLocale,
};

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 5 * 1000,
            retry: 1
        },
    },
})
const Loadable =
    // eslint-disable-next-line react/display-name
    (Component) => (props) =>
        (
            <Suspense fallback={<FullScreenLoader/>}>
                <Component {...props} />
            </Suspense>
        )

const Home = Loadable(lazy(() => import('../features/home/Home')))
const Users = Loadable(lazy(() => import('../features/user-admin/UsersView')))
const UserBase = Loadable(lazy(() => import('../features/user-admin/user-base/UserBase')))
const UserLocations = Loadable(lazy(() => import('../features/user-admin/user-locations/UserLocationView')))
const UserArtists = Loadable(lazy(() => import('../features/user-admin/user-artists/UserArtistsView')))
const UserUsageListView = Loadable(lazy(() => import('../features/user-admin/user-usage/UserUsageListView')))
const UserUsageView = Loadable(lazy(() => import('../features/user-admin/user-usage/UserUsageView')))
const Studios = Loadable(lazy(() => import('../features/studios/studioList')))
const StudioView = Loadable(lazy(() => import('../features/studios/studio-view/studioView')))
const StudioBaseView = Loadable(lazy(() => import('../features/studios/studio-view/studioBase')))
const NewStudio = Loadable(lazy(() => import('../features/studios/studio-view/newStudio')))
const StudioContacts = Loadable(lazy(() => import('../features/studios/studio-view/studioContacts')))
const StudioCampaigns = Loadable(lazy(() => import('../features/studios/studio-view/studioCampaigns')))
const StudioCommunications = Loadable(lazy(() => import('../features/studios/studio-view/studioCommunications')))
const NewStudioCommunication = Loadable(lazy(() => import('../features/studios/studio-view/newStudioCommunication')))
const StudioDuplicates = Loadable(lazy(() => import('../features/studios/duplicates/studioDuplicatesList')))
const StudioDuplicateExclusions = Loadable(lazy(() => import('../features/studios/duplicates/duplicateExclusionsList')))
const CampaignList = Loadable(lazy(() => import('../features/campaigns/campaignList')))
const CampaignView = Loadable(lazy(() => import('../features/campaigns/campaignView')))
const CampaignBase = Loadable(lazy(() => import('../features/campaigns/campaignBase')))
const NewCampaign = Loadable(lazy(() => import('../features/campaigns/newCampaign')))
const CampaignStudioList = Loadable(lazy(() => import('../features/campaigns/campaignStudioList')))
const CommunicationView = Loadable(lazy(() => import('../features/communication/communicationView')))
const CampaignStudioCommunicationView = Loadable(lazy(() => import('../features/communication/campaignStudioCommunicationView')))
const CommunicationBase = Loadable(lazy(() => import('../features/communication/communicationBase')))
const NewCommunication = Loadable(lazy(() => import('../features/communication/newCommunication')))
const InboundCallsListView = Loadable(lazy(() => import('../features/inbound-calls/inboundCallsListView')))
const MsgTemplatesView = Loadable(lazy(() => import('../features/settings/msg-templates/msgTemplatesView')))
const NewMessageTemplate = Loadable(lazy(() => import('../features/settings/msg-templates/newMessageTemplate')))
const NewMsgTemplateTranslation = Loadable(lazy(() => import('../features/settings/msg-templates/newMsgTemplateTranslation')))
const MsgTemplateEdit = Loadable(lazy(() => import('../features/settings/msg-templates/msgTemplateEdit')))
const MsgTemplateTranslationEdit = Loadable(lazy(() => import('../features/settings/msg-templates/msgTemplateTranslationEdit')))
const IncomingMessageView = Loadable(lazy(() => import('../features/communication/incoming/incomingMessageView')))



const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        errorElement: <ErrorPage/>,
        children: [
            {
                element: <AuthGuard allowedRoles={['system']}/>,
                children: [
                    {index: true, element: <Home/>},
                    {
                        path: 'users',
                        element: <Users/>,
                        children: [
                            {index: true, path: ':userId', element: <UserBase/>},
                            {path: ':userId/locations', element: <UserLocations/>},
                            {path: ':userId/artists', element: <UserArtists/>},
                            {
                                path: ':userId/usages', element: <UserUsageListView/>, children: [
                                    {path: ':usageId', element: <UserUsageView/>},
                                ]
                            }
                        ]
                    },
                    {
                        path: 'studios',
                        element: <Studios/>
                    },
                    {
                        path: 'incoming-messages',
                        element: <IncomingMessageView />
                    },
                    {
                        path: 'studios/:studioId',
                        element: <StudioView/>,
                        children: [
                            {index: true, element: <StudioBaseView/>},
                            {path: 'contacts', element: <StudioContacts/>},
                            {path: 'campaigns', element: <StudioCampaigns/>},
                            {
                                path: 'communications',
                                element: <StudioCommunications/>,
                                children: [
                                    {path: 'new', element: <NewStudioCommunication/>},
                                    {path: ':communicationsId', element: <CommunicationBase/>}
                                ]
                            }
                        ]
                    },
                    {
                        path: 'studios/new',
                        element: <NewStudio/>,
                    },
                    {
                        path: 'studios/duplicate-exclusions',
                        element: <StudioDuplicateExclusions/>,
                    },
                    {
                        path: 'studios/duplicates',
                        element: <StudioDuplicates/>,
                    },
                    {
                        path: 'campaigns',
                        element: <CampaignList/>,
                        children: [
                            {path: 'new', element: <NewCampaign/>},
                            {
                                path: ':campaignId', element: <CampaignView/>, children: [
                                    {index: true, path: "", element: <CampaignBase/>},
                                    {path: 'studios', element: <CampaignStudioList/>}
                                ]
                            }
                        ]
                    },
                    {
                        path: "communications",
                        element: <CommunicationView/>,
                        children: [
                            {
                                path: ':campaignStudioId',
                                element: <CampaignStudioCommunicationView/>,
                                children: [
                                    {
                                        path: 'new',
                                        element: <NewCommunication/>
                                    },
                                    {
                                        path: ':communicationsId',
                                        element: <CommunicationBase/>
                                    }
                                ]
                            }
                        ]
                    },
                    {path: 'inbound-calls', element: <InboundCallsListView/>},
                    {
                        path: 'msg-templates', element: <MsgTemplatesView/>, children: [
                            {path: 'translations/:translationId', element: <MsgTemplateTranslationEdit/>},
                            {path: ':id/translations/new/:locale', element: <NewMsgTemplateTranslation/>},
                            {path: 'new', element: <NewMessageTemplate/>},
                            {path: ':id', element: <MsgTemplateEdit/>}
                        ]
                    }
                ]
            },
            {
                path: "login",
                element: <Login/>
            },
            {
                path: "unauthorized",
                element: <Unauthorized/>
            }
        ]
    }
])

const AppContainer = () => {
    const {dispatch, state} = useStateContext()
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
    const locale = useMemo(() => selectLocale(state), [state])
    const mode = useMemo(() => state ? selectThemeMode(state) : 'light', [state])

    useEffect(() => {
        dispatch(setThemeMode(prefersDarkMode ? 'dark' : 'light'))
    }, [dispatch, prefersDarkMode])

    useEffect(() => {
        initializeMuiLicense()
    }, [])

    useEffect(() => {
        if (locale) {
            i18n.changeLanguage(locale).then()
        }
    }, [locale])


    const theme = React.useMemo(
        () => {
            const newTheme = createTheme({
                palette: {
                    mode,
                    orange: {
                        light: '#ffa726',
                        main: '#f57c00',
                        dark: '#ef6c00',
                        contrastText: 'rgba(255, 255, 255, 0.87)',
                    },
                    amber: {
                        light: amber[400],
                        main: amber[700],
                        dark: amber[800],
                        contrastText: 'rgba(255, 255, 255, 0.87)',
                    },
                    deepPurple: {
                        light: deepPurple[400],
                        main: deepPurple[700],
                        dark: deepPurple[800],
                        contrastText: 'rgba(255, 255, 255, 0.87)',
                    },
                    indigo: {
                        light: indigo[400],
                        main: indigo[700],
                        dark: indigo[800],
                        contrastText: 'rgba(255, 255, 255, 0.87)',
                    },
                    yellow: {
                        light: yellow[400],
                        main: yellow[700],
                        dark: yellow[800],
                        contrastText: 'rgba(0, 0, 0, 0.87)',
                    },
                    lime: {
                        light: lime[400],
                        main: lime[700],
                        dark: lime[800],
                        contrastText: 'rgba(255, 255, 255, 0.87)',
                    },
                    green: {
                        light: green[400],
                        main: green[700],
                        dark: green[800],
                        contrastText: 'rgba(255, 255, 255, 0.87)',
                    },
                    blue: {
                        light: blue[400],
                        main: blue[700],
                        dark: blue[800],
                        contrastText: 'rgba(255, 255, 255, 0.87)',
                    },
                },
            })
            return responsiveFontSizes(newTheme)
        },
        [mode],
    )
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[locale]}>
            <QueryClientProvider client={queryClient}>
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <RouterProvider router={router}/>
                </ThemeProvider>
                <ReactQueryDevtools initialIsOpen={false}/>
            </QueryClientProvider>
        </LocalizationProvider>
    );
};

export default AppContainer;
