import React, {useCallback, useMemo} from 'react';
import {AppBar, Box, IconButton, Toolbar} from "@mui/material";
import logoLight from "../public/images/KissCalLogoWithText-light.png";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import {selectThemeMode, setThemeMode, useStateContext} from "../context/StateProvider";
import LogoutIcon from '@mui/icons-material/Logout';
import {useLogout} from "../features/auth/queries";
import {useNavigate} from "react-router-dom";
import SubscriptionIcon from "./icons/SubscriptionIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShop} from "@fortawesome/free-solid-svg-icons/faShop";
import SettingsMenu from "../features/settings/SettingsMenu";
import AdjustIcon from '@mui/icons-material/Adjust';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';


const StartToolbar = () => {
    const {dispatch, state} = useStateContext()
    const navigate = useNavigate()
    const {logout} = useLogout()

    const mode = useMemo(() => state ? selectThemeMode(state) : 'light', [state])
    const handleToggleMode = useCallback(() => {
        const newMode = mode === 'light' ? 'dark' : 'light'
        dispatch(setThemeMode(newMode))
    }, [dispatch, mode])

    return (
        <AppBar>
            <Toolbar disableGutters>
                <Box display="flex" width="100%" alignItems="center" gap={1}>
                    <Box flex="1" padding={1} display="flex" alignItems="center" gap={2}>
                        <Box flex="0 0 auto" sx={{height: "2em", display: "flex", marginRight: 2, '&:hover': {cursor: "pointer"}}}>
                            <img height="100%" src={logoLight} alt="kisscal-logo" onClick={() => navigate('/')}/>
                        </Box>
                        <IconButton color="inherit" onClick={() => navigate('/users')}>
                            <SubscriptionIcon />
                        </IconButton>
                        <IconButton color="inherit" onClick={() => navigate('/studios')}>
                            <FontAwesomeIcon icon={faShop} />
                        </IconButton>
                        <IconButton color="inherit" onClick={() => navigate('/campaigns')}>
                            <AdjustIcon />
                        </IconButton>
                        <IconButton color="inherit" onClick={() => navigate('/communications')}>
                            <ContactPhoneIcon />
                        </IconButton>
                        <IconButton color="inherit" onClick={() => navigate('/inbound-calls')}>
                            <PhoneCallbackIcon />
                        </IconButton>
                        <IconButton color="inherit" onClick={() => navigate('/incoming-messages')}>
                            <MarkUnreadChatAltIcon />
                        </IconButton>

                    </Box>
                    <SettingsMenu />
                    <Box flex="0 0 Auto">
                        <IconButton color="inherit" onClick={handleToggleMode}>
                            {mode === 'dark' ? <Brightness7Icon/> : <Brightness4Icon/>}
                        </IconButton>
                    </Box>
                    <IconButton color="inherit" onClick={logout}>
                        <LogoutIcon/>
                    </IconButton>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default StartToolbar;
