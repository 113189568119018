export const studioKeys = {
    all: ['studios'],
    singleAllParams: (id) => [...studioKeys.all, id.toString()],
    singleUser: (userId) => [...studioKeys.all, 'user-id', userId.toString()],
    single: (id) => [...studioKeys.all, id],
    singleWithParams: (id, params = {}) => [...studioKeys.single(id), params],
    list: () => [...studioKeys.all, 'list'],
    names: () => [...studioKeys.all, 'names'],
    filteredList: (studioStatus, contactName, studioName) => [...studioKeys.list(), studioStatus
        , contactName || '', studioName || ''],
}

