import React, {useEffect, useMemo} from 'react';
import {Alert, IconButton, Snackbar} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {selectLastMessage, useStateContext} from "../context/StateProvider";

const InfoToaster = () => {
    const {state} = useStateContext()
    const [open, setOpen] = React.useState(false);

    const message = useMemo(() => selectLastMessage(state), [state])

    useEffect(() => {
        if (message) {
            setOpen(true)
        }
    }, [message])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small"/>
            </IconButton>
        </React.Fragment>
    );

    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            action={action}
        >
            <Alert severity={message?.type || 'info'}>{message?.message}</Alert>
        </Snackbar>
    );
};

export default InfoToaster;
